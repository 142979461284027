/**
 * Constants
 *
 * @desc Sentry constants
 */

export default {
  RELEASE: `vaarvergunningen-amsterdam-nl@${process.env.APP_VERSION}`,
  DSN: 'https://e30a9b25c1c94ad29edd686a19780b0a@o281606.ingest.sentry.io/4504514262269952',
  ENVIRONMENTS: {
    development: [
      'localhost'
    ],
    test: [
      'vaarvergunningen.amsterdam.nl.test.uselab.com'
    ],
    acceptance: [
      'acceptatie-vaarvergunningen.amsterdam.nl'
    ],
    production: [
      'vaarvergunningen.amsterdam.nl'
    ]
  }
}
