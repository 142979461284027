const scrollTo = {
  mounted: (el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      const targetEl = document.querySelector(el.getAttribute('href'));
      if (targetEl) {
        targetEl.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }
}
export default scrollTo
