const state = {
  loading: false
}

const actions = {
  loading ({ commit }, visibility) {
    commit('SET_LOADING', visibility);
  }
}

const mutations = {
  SET_LOADING (state, visibility) {
    state.loading = visibility;
  }
}

const getters = {
  loading: state => state.loading
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
