import { init, BrowserTracing, vueRouterInstrumentation } from '@sentry/vue';
import sentryHelper from './helpers'
import config from './config'

/**
 * createSentry
 *
 * @desc Creates a new Sentry instance
 * @return Return initialization for these conditions
 *  Current environment is `null` (see config.js)
 *  Current environment is not production and debug mode is false
 */

export default function createSentry (app, router) {
  const sentryConfig = {
    app,
    ...config,
    integrations: [
      new BrowserTracing({
        ...router ? { routingInstrumentation: vueRouterInstrumentation(router) } : {},
        tracePropagationTargets: ['localhost', 'vaarvergunningen.amsterdam.nl', /^\//]
      })
    ]
  }

  window.APP_SENTRY = {
    initialized: false,
    debug: sentryHelper.isDebug(),
    environment: sentryConfig.environment,
    release: sentryConfig.release
  };

  // Return -> environment is null
  // Return -> environment is not production and debug mode is false
  if (sentryConfig.environment === null) return null;
  else if (sentryConfig.environment !== 'production' && !sentryHelper.isDebug()) return null;

  init(sentryConfig);

  window.APP_SENTRY.initialized = true;

  if (sentryHelper.isDebug()) {
    setTimeout(() => {
      console.error('setTimeout/sentryConfig', sentryConfig) // eslint-disable-line no-console
      throw new Error('[debug] Sentry Error');
    }, 5000)
  }
}
