import { createApp } from 'vue/dist/vue.esm-bundler.js'
import createSentry from 'shared/sentry/sentry'

function AppException (message) {
  this.message = message;
  this.name = 'AppException';
}

export default function createModule ({ App, components, router, dependencies, directives, version }) {
  if (!App) throw new AppException('App is an required parameter.');

  // Merge deps
  const modules = [
    ...router ? [router] : [],
    ...dependencies
  ];

  // Create app
  const app = createApp(App);

  // Create Sentry
  createSentry(app, router);

  // Add components
  if (typeof components === 'object' && Object.entries(components).length) {
    Object.entries(components).forEach(([key, component]) => app.component(key, component));
  }

  // Add dependencies
  if (Array.isArray(modules) && modules.length) {
    modules.forEach(module => app.use(module));
  }

  // Bind configurations
  app.config.compilerOptions.whitespace = 'preserve'

  // Bind directives
  if (typeof directives === 'object' && Object.entries(directives).length) {
    Object.entries(directives).forEach(([key, directives]) => app.directive(key, directives));
  }

  // Mount
  app.mount('#app')

  // Bind version
  window.APP_VERSION = version || null;
}
