/* -----------------------------------------------------------------
 * 3rd party
 * -------------------------------------------------------------- */
import VueDOMPurifyHTML from 'vue-dompurify-html'

/* -----------------------------------------------------------------
 * core
 * -------------------------------------------------------------- */
import createModule from 'shared/core/module'
import i18n from 'shared/core/localization'
import components from 'shared/components/index.js';
import directives from 'shared/directives/index.js'
import store from 'shared/store/index';
import App from './App.vue'

/* -----------------------------------------------------------------
 * define vars
 * -------------------------------------------------------------- */
const router = null;

/* -----------------------------------------------------------------
 * init
 * -------------------------------------------------------------- */
createModule({
  App,
  components,
  router,
  dependencies: [i18n, store, VueDOMPurifyHTML],
  directives,
  version: process.env.APP_VERSION
});
