/**
 *
 * Localization
 *
 * Service which returns the i18nConfig object...
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import { createI18n } from 'vue-i18n'

const locale = localStorage.locale || 'nl';
const i18nConfig = {
  locale,
  fallbackLocale: 'en',
  messages: {},
  warnHtmlInMessage: 'off' // Disable of the Detected HTML in message...
};

try {
  i18nConfig.messages[locale] = require('../lang/' + `${locale}.json`);
} catch (ex) {
  i18nConfig.messages[locale] = require('../lang/nl-NL.json');
}

export default createI18n(i18nConfig);
